import _ from "lodash";
window._ = _;

import "./bootstrap";
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

// import axios from 'axios';
// window.axios = axios;

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });

//jQuery
import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

//toastr
window.toastr = require("./components/toastr");

//flatpickr datetimerange
import "flatpickr";
const Indonesia = require("flatpickr/dist/l10n/id.js").default.id;

//select2
import "select2";
// require("./components/clipboard");

require("./components/bulma-modal-fx");

// bulma modal

// DataTable
import DataTable from "datatables.net-bm";
// import "datatables.net-buttons-bm";

// Phone Formatter
// import intlTelInput from "intl-tel-input";

// const input = document.querySelector("#phone");
// if (input) {
//     intlTelInput(input, {
//         utilsScript: "/js/components/utils.js",
//         onlyCountries: ["id"],cle
//         separateDialCode: true,
//         nationalMode: true,
//         hiddenInput: "phone",
//         customPlaceholder: function (
//             selectedCountryPlaceholder,
//             selectedCountryData
//         ) {
//             return "812345678";
//         },
//     });
// }
flatpickr(".daterange", {
    mode: "range",
    altInput: true,
    altFormat: "j F Y",
    maxDate: new Date(),
    parseDate: true,
    locale: Indonesia,
}); // Jquery Dependency

$("input[data-type='currency']").on({
    keyup: function () {
        formatCurrency($(this));
    },
    blur: function () {
        formatCurrency($(this), "blur");
    },
});

function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function formatCurrency(input, blur) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    var input_val = input.val();

    // don't validate empty input
    if (input_val === "") {
        return;
    }

    // original length
    var original_len = input_val.length;

    // initial caret position
    var caret_pos = input.prop("selectionStart");

    // check for decimal
    if (input_val.indexOf(",") >= 0) {
        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = input_val.indexOf(",");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = formatNumber(left_side);

        // validate right side
        right_side = formatNumber(right_side);

        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
            right_side += "00";
        }

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        input_val = left_side + "," + right_side;
    } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = formatNumber(input_val);
        input_val = input_val;

        // final formatting
        if (blur === "blur") {
            input_val += ",00";
        }
    }

    // send updated string to input
    input.val(input_val);

    // put caret back in the right position
    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    input[0].setSelectionRange(caret_pos, caret_pos);
}

$(".view-password").click(function () {
    var check_attr = $(this)
        .parent()
        .find('input[type="password"]')
        .attr("type");
    if (check_attr == "password") {
        $(this).parent().find('input[type="password"]').attr("type", "text");
        $(this).find("img").attr("src", "/images/icons/eye-close.svg");
    } else {
        $(this).parent().find('input[type="text"]').attr("type", "password");
        $(this).find("img").attr("src", "/images/icons/eye.svg");
    }
});
// navbar
$(".navbar-burger").click(function () {
    $("aside").toggleClass("is-active");
});
